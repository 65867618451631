import Store from '@/store'

export const dataLayerArticleBrand = (articleBrand) => {
  if (Store.getters.vlm) {
    return
  }

  // Ensure window.dataLayer is initialized as an array if not already
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    page_info: {
      article_brand: articleBrand
    }
  })
}
