<template>
  <section class="hp-topic-offer-filter">
    <Select
      v-model="filter.site"
      :options="sites"
      noEmptyValue
      :label="$tc('hpTopicOffer.site')"
      id="filter_site"
      disable-form-group
      class="hp-topic-offer-filter__sites"
    />
    <Datepicker
      type="date"
      v-model="filter.date"
      id="filter_date"
      :label="$tc('hpTopicOffer.date')"
      disable-form-group
      class="hp-topic-offer-filter__date"
    />
    <Select
      v-model="filter.department"
      :options="departments"
      optionTitle="name"
      :label="$tc('hpTopicOffer.base')"
      id="filter_department"
      disable-form-group
    />
    <ButtonSearch
      @click="setFilter"
      class="hp-topic-offer-filter__btn-search"
    />
  </section>
</template>

<script>
import Select from '@/components/form/select/Select'
import Datepicker from '@/components/form/Datepicker'
import ButtonSearch from '@/components/buttons/ButtonSearch'

export default {
  name: 'HpTopicOfferFilter',
  components: {
    ButtonSearch,
    Select,
    Datepicker
  },
  data () {
    return {
      filter: this.$store.getters['hpTopicOffer/filter']
    }
  },
  computed: {
    departments () {
      return this.$store.getters['department/all']
    },
    filterChanged () {
      return JSON.stringify(this.filter)
    },
    sites () {
      return this.$store.getters['site/all'].filter(site => site.enabled)
    }
  },
  methods: {
    setFilter () {
      this.$store.commit('hpTopicOffer/setFilter', this.filter)
      this.$emit('fetch')
    }
  },
  watch: {
    filterChanged () {
      this.setFilter()
    }
  }
}
</script>

<style scoped lang="scss">
  .hp-topic-offer-filter {
    display: grid;
    align-items: end;
    grid-gap: 1.25rem;
    margin-bottom: 1.75rem;
    @media (min-width: 0px) and (max-width: 999px) {
      grid-template-columns: auto min-content;
    }
    @media (min-width: 1000px) {
      grid-template-columns: 1fr 1fr 1fr min-content;
    }
    &__sites,
    &__date {
      @media (min-width: 0px) and (max-width: 999px) {
        grid-column: 1/-1;
      }
    }
  }
</style>
